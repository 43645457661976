<template>
  <div>
    <van-divider
      :style="{ color: '#464646', borderColor: '#464646', padding: '0 1rem' }"
    >
      申请项目
    </van-divider>
    <van-notice-bar
      wrapable
      :scrollable="false"
      text="新项目请先使用自己手机接一条以确定格式，避免浪费时间 模板例子：【XXX网】XXX你获得的验证码是445214"
    />
    <van-notice-bar
      wrapable
      :scrollable="false"
      text="2次多次收短信模板:【2次XXX网】你获得的验证码是445214 "
    />
    
    <div class="div">
      <van-field
        v-model="message"
        rows="2"
        autosize
        label="模板"
        type="textarea"
        maxlength="255"
        placeholder="模板例子：【XXX网】XXX你获得的验证码是445214"
        show-word-limit
      />
      <van-button plain hairline type="info" color="#464646" @click="btn_click"
        >提交申请</van-button
      >
    </div>
    <van-divider
      :style="{ color: '#464646', borderColor: '#464646', padding: '0 1rem' }"
    >
      项目申请记录
    </van-divider>
    <div style="height=100%">
      <div class="msg">
        <table width="90%" class="table">
          <caption>
            <!--<h2>项目申请记录</h2> -->
          </caption>
          <thead>
            <tr>
              <th>项目ID</th>
              <th>状态</th>
              <th>模板</th>
            </tr>
          </thead>
          <tr
            v-for="item in list"
            :key="item.id"
            v-clipboard:copy="item.modle"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            <td>
              {{ item.Pid }}
            </td>
            <td>{{ item.note }}</td>
            <td>{{ item.modle }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "add_project",
  data() {
    return {
      message: "",
      list: [],
    };
  },
  methods: {
    async btn_click() {
      if (this.message.length === 0) {
        this.$dialog.alert({ message: "项目都不输入,你小子是在玩我？" });
        return;
      }
      let ret = await this.$api.fn_user_project_list_push(this.message);
      ret = ret.data;
      if (ret.message === "ok") {
        this.$toast("提交成功");
      } else {
        this.$toast(ret.message);
      }
    },
    //  复制的方法
    onCopy() {
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    },
  },
  async mounted() {
    let ret = await this.$api.fn_user_get_project_record();
    ret = ret.data;
    if (typeof ret == "string") {
      ret = ret.replace("\\/g", "");
      ret = eval("(" + ret + ")");
    }
    ret = ret.data;
    ret.forEach((element) => {
      let note = "";
      if (element.state === "0") {
        note = "未审核";
      } else {
        note = "已审核:" + element.note;
      }

      this.list.push({
        modle: element.modle,
        Pid: element.project_id,
        note: note,
      });
    });
  },
};
</script>

<style scoped>
.div {
  text-align: center;
  margin-top: 2.48%;
  margin-left: 1rem;
  margin-right: 1rem;
  border: 1px solid #464646;
}
table {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
}
table td,
table th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 12px;
}
table thead th {
  background-color: #cce8eb;
  width: 50px;
}
table tr:nth-child(odd) {
  background: #fff;
}
table tr:nth-child(even) {
  background: #cbf3f3;
}
.msg {
  margin-bottom: 100px;
}
.pag {
  width: 100%;
  position: fixed;
  bottom: 50px;
  left: 0;
  z-index: 99;
}
</style>